import React from "react";

export default function () {
    return <div>
        <h1>Multimatograf 2006 - 2010</h1>

        <p>Выставка ретрокомпьютеров проводилась на фестивале &quot;Мультиматограф&quot; с 2006-го по 2014-й год. За это
            время в общей сложности было показано более 50 раритетных компьютеров. Все компьютеры в рабочем состоянии.
            ЭКСПОНАТЫ&nbsp;МОЖНО&nbsp;ТРОГАТЬ&trade;</p>

        <hr/>
        <h2>Multimatograf 2</h2>
        <h3>25-26 марта 2006 года, Вологда, <br />выставочный комплекс &laquo;Русский&nbsp;Дом&raquo;</h3>
        <img alt="" src="./media/cover2006.jpg"/>
        <p>Первая выставка ретрокомпьютеров на фестивале &quot;Мультиматограф&quot; прошла в 2006-м году. Было
            представлено 12 компьютеров: несколько моделей Apple Macintosh, ZX Spectrum совместимые компьютеры, IBM PC
            совместимые - i80280, i80386, Amstrad CPC 6128, Atari 1040STe.</p>

        <p><a href="https://goo.gl/photos/1oVC2FM2YqdR8j2s7">Фотографии Мультиматограф 2</a>.</p>

        <hr/>
        <h2>Multimatograf 3</h2>
        <h3>24-25 марта 2007 года, Вологда, <br />выставочный комплекс &laquo;Русский&nbsp;Дом&raquo;</h3>
        <img alt="" src="./media/cover2007.jpg"/>
        <p>Выставка ретрокомпьютеров была слегка расширена, всего было показано 15 компьютеров. Но общее направление
            осталось прежним: ZX Spectrum совместимые, IBM PC, теперь включая i8086, и макинтоши, которых тоже стало
            больше.</p>

        <p><a href="https://goo.gl/photos/Rfm3tA7dUwikY5Bx5">Фотографии Мультиматограф 3</a>.</p>

        <hr/>
        <h2>Multimatograf 4</h2>
        <h3>29-30 марта 2008 года, Вологда, <br />выставочный комплекс &laquo;Русский&nbsp;Дом&raquo;</h3>
        <img alt="" src="./media/cover2008.jpg"/>
        <p>Впервые в рамках выставки состоялся чемпионат &quot;old game compo&quot;. Участники проходили игру Flying
            Shark на ZX Spectrum совместимых компьютерах. Каждому игроку давалось пять минут. Побеждал набравший больше
            всего очков.</p>

        <h3>Победители oldgame compo:</h3>
        <ol>
            <li>Романов Виктор</li>
            <li>Чеканов Владимир</li>
            <li>Шувалов Дмитрий</li>
            <li>Рыстаков Антон</li>
            <li>Балашова Мария</li>
        </ol>
        <p>Все победители получили призы и памятные подарки.</p>

        <h3>Ссылки</h3>
        <ul>
            <li><a href="https://photos.app.goo.gl/FdKsOdyhp15KZM763">Google Фото</a></li>
            <li><a href="https://www.youtube.com/watch?v=Z0khIwEtvZQ">Видео с выставки</a></li>
        </ul>

        <hr/>

        <h2>Multimatograf 5</h2>
        <h3>28-29 марта 2009 года, Вологда, <br />выставочный комплекс &laquo;Русский&nbsp;Дом&raquo;</h3>
        <img alt="" src="./media/cover2009.jpg"/>
        <p>Состав выставки был очень расширен, более половины компьютеров выставлялось впервые. Часть экспонатов привез
            из Воронежа Александр Кандауров, организатор фестиваля ретрокомпьютеров &quot;ArtField&quot;.</p>
        <p>oldgame compo прошло в том же формате, что и в предыдущем году. Соревнование проводилось по игре Chuckie Egg
            (1984), всего в конкурсе приняли участие 18 человек.</p>

        <h3>Победители oldgame compo:</h3>
        <ol>
            <li>Андрей Фролов, Вологда</li>
            <li>Всеволод Потапов, Санкт-Петербург</li>
            <li>Станислав Рожков, Йошкар-Ола</li>
        </ol>

        <h3>Ссылки</h3>
        <ul>
            <li><a href="https://photos.app.goo.gl/2PfK0pBLYVBmebS62">Google Фото</a></li>
            <li><a href="https://rutube.ru/video/71722574d7dda605ded9ad69e593f6a2/">Видеозарисовка с выставки</a></li>
        </ul>

        <hr/>
        <h2>Multimatograf 6</h2>
        <h3>24-25 апреля 2010 года, Вологда, <br />выставочный комплекс &laquo;Русский&nbsp;Дом&raquo;</h3>
        <img alt="" src="./media/cover2010.jpg"/>
        <p>Очередная выставка прошла в формате &quot;три часа на всё&quot;. В третий раз состоялся конкурс oldgame
            compo. На этот раз участники соревновались в прохождении игры Manic Miner (1983). В конкурсе приняли участе
            15 человек, все они получили подарки от Kaspersky Lab.</p>

        <p><a href="https://goo.gl/photos/YXV8suzLt3YWmk8J8">Фотографии Мультиматограф 6</a>.</p>
   </div>
        ;
}