import React from "react";

export default function () {
    return <div>
        <h1>Multimatograf demoparty</h1>
        <img alt="" src="./media/coverIndex.png"/>
        <br />
        <br />
        <p>Иногда они возвращаются...</p>
   </div>;
}